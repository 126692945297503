.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 9.0625rem;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.5625rem rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;
  width: max-content;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  min-height: 1.75rem;
  cursor: pointer;
  color: #abafd1;
  padding: 0 0.875rem;
  border: none;
  outline: none;
  font-family: inherit;
}

.h40 {
  min-height: 2.5rem;
}

.pad {
  padding: 0 0.9375rem;
  margin-left: auto;
}

.paddingSide {
  padding: 0 0.2rem;
}

.dark .btn {
  background-color: #353c53;
}

.btn svg,
.btn img {
  margin-right: 0.625rem;
}

.text {
  font-weight: 400 !important;
}

.mr-14 {
  margin-right: 0.875rem;
}

.dark .text {
  color: #5a5d74;
}

.dark .lightbtn {
  background-color: #3b435e !important;
}

.btn:active {
  box-shadow: inset 0rem 0rem 0.5625rem rgba(0, 0, 0, 0.15);
}

.disabled {
  pointer-events: none;
  cursor: not-allowed !important;
}

.mx-auto {
  margin: 0 auto;
}

.dark .lightish {
  background-color: #464f6d;
  color: #5a5d74;
}

.px-1 {
  padding: 0 1rem;
}

.priceUpdate-btn {
  min-width: 5.0625rem;
  margin-left: 0.325rem;
}

.priceUpdate-btn span.text {
  margin: 0px !important;
  color: #8ff093;
}

.openPanel-btn {
  max-height: 1.75rem;
}

.openPanel-btn span.text {
  margin: 0px !important;
}

.ask-btn span.text {
  margin: 0px !important;
  color: #767bfa;
}
.ask-btn.asked span.text {
  margin: 0px !important;
  color: #fa7676 !important;
}

.ask-btn {
  min-width: 4.3rem !important;
}

.share-link-btn {
  min-width: 8.3rem !important;
}

.qr-btn {
  min-width: 3rem;
  padding-right: 0.5rem;
}

.conf-cashout {
  min-width: 11.875rem !important;
}

.cashout {
  box-shadow: 0px 0px 2px rgb(0 0 0 / 35%);
  border-radius: 5px;
  padding: 0.625rem;
}

.cashout span.text {
  margin: 0px !important;
  color: #12c519;
}

.bigPurpleBtn {
  background-color: #767bfa;
  color: #fff;
  padding: 1.1875rem;
  width: 21.125rem;
  height: 4rem;
}

.bigPurpleBtn .text {
  font-weight: 600 !important;
}

/* .asked {
  box-shadow: inset 0rem 0rem 0.5625rem rgb(0 0 0 / 15%) !important;
} */

.bannerBottomButton {
  border-radius: 0.3125rem;
  border: 1px solid #767bfa;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
  padding: 0.375rem 0.75rem;
  background-color: transparent;
  color: #abafd1;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bannerBottomButton.disabled {
  opacity: 0.5;
}

.bannerBottomButton img {
  width: 15px;
}

.infoButton {
  border-radius: 0.3125rem;
  border: 1px solid #767bfa;
}

.planDetailsButton {
  border-radius: 0.3125rem;
  background: #767bfa !important;
  box-shadow: 0rem 0rem 0.375rem 0rem rgba(0, 0, 0, 0.15);
  color: #fff;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease-in-out;
}

.planDetailsButton:hover {
  background: rgba(118, 123, 250, 0.8);
}

.createCaptcha {
  color: #5cad60;
  min-width: 5.8125rem;
  font-size: 0.75rem;
}

.enableFairness {
  margin-right: 1rem;
  font-size: 0.75rem;
}

.updateFairness {
  margin-left: 1rem;
  font-size: 0.75rem;
}

.delete {
  border-radius: 0.1875rem;
  background: #ea6768;
  -webkit-border-radius: 0.1875rem;
  -moz-border-radius: 0.1875rem;
  -ms-border-radius: 0.1875rem;
  -o-border-radius: 0.1875rem;
  text-align: center;
  color: #e4e5ed;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  max-width: 5.25rem;
  min-height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deleteStaffBtn {
  background: #fa7676;
  color: #f6f7ff;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  min-width: 5.9375rem;
  height: 1.25rem;
}

.deleteStaffBtn span {
  margin-right: 0 !important;
}

.copyPosLink {
  border-radius: 5px;
  border: 1px solid #767bfa;
  color: #f6f7ff;
  background: #767bfa;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.15);
}

.iconButton {
  border-radius: 5px;
  background: #767bfa;
  color: #f6f7ff;
  font-size: 15px;
  font-weight: 600;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.15);
  min-height: 50px;
  min-width: 260px;
  margin-top: 10px;
}

.iconButton.disabled {
  background: rgba(118, 123, 250, 0.55);
}

.downloadAll {
  color: #767bfa;
}

.saveButton {
  font-family: "Poppins" !important;
}
