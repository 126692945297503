.tabs {
  display: flex;
  align-items: center;
  gap: 45px;
  transform: translateY(10px);
}

.tab {
  cursor: pointer;
  color: #abafd1;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.tab.active {
  color: #000000;
}

.configContainer {
  margin-top: 18px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.addNewContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
  min-height: 370px;
  background-image: url("../../assets/border.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.addButton {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 20px;
  padding: 8px 16px;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.plusIcon {
  color: #5555ff;
  margin-right: 8px;
  font-size: 24px;
}

.card {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-height: 370px;
}

.header {
  text-align: center;
  color: #323a52;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  padding: 20px;
  min-height: 86px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.area {
  color: #323a52;
  padding: 0 20px;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  width: 100%;
  padding-top: 30px;
}

.iconChain {
  background-size: contain;
  background-repeat: no-repeat;
  height: 50px;
  width: 50px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 12px;
}

.iconChain img {
  width: 50px;
}

.button {
  border: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  width: 100%;
  max-width: 238px;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1.3px;
  min-height: 40px;
  border-radius: 5px;
}

.copy {
  background-color: #767bfa;
}

.delete {
  background-color: #fa7676;
}

.border {
  height: 1px;
  background-color: #abafd1;
}

.actionButtons {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.overviewContainer {
  display: grid;
  grid-template-columns: 2.25fr 1fr;
  gap: 25px;
  height: 100%;
}

.staffContainer {
  display: flex;
  flex-direction: column;
}

.staffContainerHeader,
.staffContainerRow {
  display: grid;
  grid-template-columns: 1fr 1fr 3fr 1.5fr;
  align-items: center;
  padding: 0 30px;
  font-size: 15px;
}

.staffContainerHeader {
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 10px;
}

.staffContainerRow {
  border-radius: 7px;
  background: #f6f7ff;
  box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.1);
  min-height: 50px;
  margin-bottom: 5px;
}

.viewButton {
  background-color: #767bfa;
  color: white;
  width: 45%;
}

.deleteButton {
  background-color: #fa7676;
  color: white;
  width: 45%;
  margin-left: 5%;
}

.deleteButton,
.viewButton {
  border: none;
  min-height: 24px;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  font-family: inherit;
}

.staffList {
  padding: 20px;
}

.staffTable tr {
  min-height: 50px;
}

.overviewButtons {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.addBtns {
  max-width: 69.2%;
}

.boxHeader {
  font-weight: 600;
}

.rightSection {
  padding-top: 1.275rem;
}

.uploadWrapper {
  border-radius: 7px;
  background: #f6f7ff;
  min-height: 50px;
  width: 100%;
  display: flex;
  padding-left: 20px;
  align-items: center;
  text-decoration: underline;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1) inset;
}

.uploadText {
  color: #323a52;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
}

.emptyCardDesignPreview {
  color: #abafd1;
  text-align: center;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 100%;
  width: 100%;
  max-width: 14ch;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.cardDesignPreview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.cardDesignPreviewContainer {
  position: relative;
  width: 255px;
  height: 382px;
}

.squareCardDesignPreviewContainer {
  position: relative;
  width: 280px;
  height: 280px;

  svg {
    width: 100%;
    height: 100%;
    transform: scale(1.15);
  }

  .horizontalDimension {
    bottom: -2rem;
  }

  .previewLogoContainer {
    margin-top: -2rem;
  }
}

.cardDesignPreviewContent {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.previewLogo {
  object-fit: contain;
  height: 18px;
  width: max-content;
  margin: 0 auto;
}

.previewLogoContainer {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.previewNameShortContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.previewNameShort {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
}

.squadeCardBackDesignPreview {
  width: 228px;
  height: 228px;
  border-radius: 32px;
  margin-top: 26px;

  position: relative;
  overflow: hidden;
  transform: rotate(45deg);

  .bg {
    content: "";
    position: absolute;
    width: 141.4%;
    height: 141.4%;
    top: -20.7%;
    left: -20.7%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 25%;
    transform: rotate(-45deg);
  }
}

.previewNameContainer {
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 1rem;
  border-radius: 10px;
}

.adminRole {
  width: max-content;
  border-radius: 0.625rem;
  padding: 0.3125rem 1.875rem;
  margin: 0 auto;
  margin-top: 15px;
  color: #fff;
  font-weight: 300;
}

.qr {
  width: 90px;
  height: 90px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid;
  margin: 0 auto;
  margin-top: 1.25rem;
  padding: 8px;
}

.qrCode {
  width: 100%;
  height: 100%;
}

.squareQrCodeContainer {
  width: 60px;
  height: 60px;
  margin-top: 0.75rem;
}

.switchButtonContainer {
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: #f6f7ff;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.15);
  margin-top: 5.5rem;
}

.switchButton {
  padding: 2px 18px;
  border-radius: 5px;
  cursor: pointer;
  color: #abafd1;
}

.switchButton.active {
  background: #767bfa;
  color: #fff;
}

.horizontalDimension {
  position: absolute;
  bottom: -1rem;
  left: 0;
  width: 100%;
  height: 1px;
  border-top: 1px solid #000;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  padding-top: 2px;

  &::after {
    content: "";
    position: absolute;
    top: -50%;
    left: 0;
    height: 20px;
    background-color: #000;
    width: 1px;
    transform: translate(0, -50%);
  }

  &::before {
    content: "";
    position: absolute;
    top: -50%;
    right: 0;
    height: 20px;
    background-color: #000;
    width: 1px;
    transform: translate(0, -50%);
  }
}

.verticalDimension {
  position: absolute;
  top: 0;
  left: -1rem;
  width: 1px;
  writing-mode: vertical-lr;
  padding-left: 4px;
  height: 100%;
  border-right: 1px solid #000;
  font-size: 10px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  transform: rotate(180deg);

  &::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 4px;
    height: 20px;
    background-color: #000;
    width: 1px;
    transform: rotate(90deg);
  }

  &::before {
    content: "";
    position: absolute;
    top: -10px;
    right: -1px;
    height: 20px;
    background-color: #000;
    width: 1px;
    transform: rotate(90deg);
  }
}

.cardBackDesignPreview {
  width: 255px;
  height: 382px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
