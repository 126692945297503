.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1.875rem 2.375rem;
  grid-template-rows: 22.5625rem 1fr;
}

.userDetailsContainer {
  padding: 1.25rem;
  border-radius: 0.9375rem;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem 0rem rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  flex-wrap: wrap;
  gap: 1.3125rem 1.25rem;
}

.userDetails {
  white-space: nowrap;
}

.userDetailsItem {
  color: #323a52;
  font-family: Poppins;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.userDetailsValue {
  color: #323a52;
  font-family: Poppins;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-left: 0.3125rem;
}

.planDetails {
  border-radius: 0.9375rem;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem 0rem rgba(0, 0, 0, 0.15);
  padding: 1.25rem;
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.planDetailsTitle {
  color: #323a52;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.planDetailsImage {
  width: 3.8125rem;
  height: 3.8125rem;
}

.planDetailsSubtitle {
  color: #323a52;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0.4375rem;
}

.profitLossGraphContainer {
  padding: 0.8125rem 1.25rem;
  border-radius: 0.9375rem;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem 0rem rgba(0, 0, 0, 0.15);
}

.profitLossGraphItem {
  color: #323a52;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.profitLossGraphValue {
  color: #12c519;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  margin-left: 0.625rem;
  line-height: normal;
}

.profitLossTextContainer {
  margin-top: 1.25rem;
}

.graphContainer {
  margin-left: -1rem;
  margin-right: -1rem;
  height: 78%;
}

.twoColumn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2.375rem;
}

.boxContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.box {
  padding: 1.125rem 1.25rem;
  border-radius: 0.9375rem;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem 0rem rgba(0, 0, 0, 0.15);
  position: relative;
}

.imageContainer {
  border-radius: 50%;
  border: 0.0625rem solid #767bfa;
  aspect-ratio: 1;
  width: 4.625rem;
  height: 4.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.25rem;
}

.count {
  color: #323a52;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0.9375rem;
  margin-bottom: 1.25rem;
}

.increment {
  padding: 0.1875rem 0;
  text-align: center;
  border-radius: 0.3125rem;
  background: #767bfa;
  color: #f6f7ff;
  text-align: center;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0.3125rem;
}

.lastUpdate {
  color: #323a52;
  text-align: center;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.comingSoon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  color: #323a52;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  width: 100%;
}

.royalties {
  background: url("../../assets/comingSoon.webp");
  background-size: cover;
  background-position: center;
}

.gridContainer {
  display: grid;
  grid-template-columns: 7.5rem repeat(6, 1fr) 9.375rem;
  grid-gap: 1.25rem;
  color: #323a52;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0.625rem;
  margin-top: 1.25rem;
}

.scrollContainer {
  max-height: 30.625rem;
  height: 40vh;
  overflow-y: auto;
  margin: 0 -1rem;
  padding: 0 1rem;
}

.values {
  border-radius: 0.4375rem;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem 0rem rgba(0, 0, 0, 0.1);
  color: #323a52;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 300;
  min-height: 5rem;
  align-items: center;
  line-height: normal;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.values:hover {
  background: #f4f5f8;
  box-shadow: 0rem 0rem 1.0625rem 0rem rgba(0, 0, 0, 0.2);
}

.status {
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  margin-right: 0.625rem;
}

.normal {
  font-weight: 400;
}

.eventImage {
  overflow: hidden;
  border-radius: 0.4375rem 0 0 0.4375rem;
  -webkit-border-radius: 0.4375rem 0 0 0.4375rem;
  -moz-border-radius: 0.4375rem 0 0 0.4375rem;
  -ms-border-radius: 0.4375rem 0 0 0.4375rem;
  -o-border-radius: 0.4375rem 0 0 0.4375rem;
  height: 100%;
}

.eventImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.loader {
  width: max-content;
  margin: auto;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  min-width: 9.0625rem;
}
