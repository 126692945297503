.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.reqText {
  font-size: 0.9375rem;
  font-weight: 500;
}

.container img {
  margin-top: 1.25rem;
  margin-bottom: 0.625rem;
}

.key {
  color: #323a52;
  text-align: center;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.1125rem;
  border-radius: 0.3125rem;
  background: #f6f7ff;
  width: 21.125rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
  border: 0.0625rem solid #767bfa;
  box-shadow: 0rem 0rem 0.6875rem 0rem rgba(0, 0, 0, 0.15);
}

.header {
  color: #323a52;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.box {
  border-radius: 15px;
  background: #f6f7ff;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.15);
  height: 100%;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 0.9375rem;
  height: 100%;
}

.header span {
  color: #95979f;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 300;
  margin-left: 0.3125rem;
  line-height: normal;
}
