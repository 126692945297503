.loader {
  display: flex;
  align-items: center;
}

.loader img {
  width: 1.5rem;
  height: 1.5rem;
}

.loading {
  animation: loading linear 0.2s infinite;
  animation-direction: normal;
  -webkit-animation: loading linear 0.2s infinite;
}

@keyframes loading {
  0% {
  }

  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}
