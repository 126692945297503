.headerColumns,
.itemColumns {
  grid-template-columns: 1.5fr 1fr 1fr 0.25fr;
}

.itemRows {
  height: 100%;
  overflow-y: auto;
}

.activitiesImg {
  margin-left: auto;
  justify-self: flex-end;
  padding-right: 1rem;
}

.activitiesImg img:first-child {
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}
