.bannerContainer {
  background-image: url("../../assets/bannerImg.webp");
  background-size: cover;
  background-position: center;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
  padding-left: 4.375rem;
  padding-right: 2.0625rem;
  padding-bottom: 1.4375rem;
  padding-top: 2.5rem;
  position: relative;
  min-height: 22.4375rem;
  display: flex;
  flex-direction: column;
}

.eventDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.eventDate {
  min-height: 55px;
  border-right: 1px solid #abafd1;
  padding-right: 1rem;
  margin-right: 1rem;
}

.month {
  color: #fff;
  text-align: center;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.date {
  color: #fff;
  text-align: center;
  font-size: 1.5625rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.eventName {
  color: #fff;
  font-size: 1.5625rem;
  font-style: normal;
  font-weight: 300;
  margin-right: 3rem;
  line-height: normal;
}

.eventLocation span {
  color: #abafd1;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.eventlocation span {
  color: #fff;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 3px;
}

.eventImage {
  margin-left: 4rem;
}

.eventImage img {
  width: 9rem;
  height: 6rem;
  object-fit: cover;
  object-position: center;
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}

.seatDetails {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
}

.seatDetails > div {
  flex-basis: 100%;
}

.seatDetailsTitle {
  color: #abafd1;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.seatDetailsValue {
  color: #fff;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.bannerBottom {
  display: flex;
  justify-content: flex-end;
  position: relative;
  gap: 20px;
  margin-top: auto;
}

.saleType {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.border {
  height: 3px;
  border-radius: 0.625rem;
  max-width: 18rem;
  width: 25%;
  position: absolute;
  bottom: -0.375rem;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
}

.info {
  margin-left: auto;
  width: max-content;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 4px;
  position: relative;
}

.infoTextContainer {
  transform-origin: top left;
  padding: 0.625rem;
  border-radius: 0.3125rem;
  background: #f6f7ff;
  position: absolute;
  left: 98%;
  top: 0;
  z-index: 1;
  width: 18.75rem;
  box-shadow: 0rem 0rem 0.375rem 0rem rgba(0, 0, 0, 0.25);
}

.infoTextHeader {
  color: #181818;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.infoText {
  color: #181818;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.section {
  margin-top: 0.9375rem;
}

.left {
  position: absolute;
  left: 20px;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
}

.right {
  position: absolute;
  right: 20px;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
}

.venueName span {
  color: #fff;
}

.seatDetailsContainer {
  max-height: 140px;
  overflow: auto;
  margin-right: 2rem;
}

.seatDetailsContainer::-webkit-scrollbar {
  width: 1px;
}

.active {
  color: #12c519;
}

.noEvents {
  color: #fff;
  font-size: 1.5625rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-right: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
