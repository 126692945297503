.container {
  position: relative;
  z-index: 10;
  max-width: 32.5rem;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
}

.loginPage {
  min-height: 100vh;
  z-index: 100;
  background-color: #f6f7ff;
  position: absolute !important;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #f6f7ff;
}

.inputsWrapper {
  margin-top: auto;
  margin-bottom: 6rem;
}

.dark .login-page {
  background-color: #262c41;
}

.bg {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  background-color: #f6f7ff;
}

.dark .bg {
  background-color: #353c53;
}

.label {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
  margin-bottom: 1.5625rem;
}

.or {
  margin-top: 2.25rem;
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
  text-align: center;
}

.mt {
  margin-top: 3.3125rem;
}

.logo {
  max-width: 22.75rem;
  margin: 0 auto;
  margin-bottom: 3.125rem;
}

.smtext {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}

.checkbox {
  height: max-content;
  width: max-content;
}

.loginBtn {
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 2.8125rem;

  cursor: pointer;
  height: 5.25rem;
  width: 16.25rem;
  margin: 3.75rem auto 0 auto;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark .login-btn {
  background: #353c53;
}

.login-btn:hover {
  box-shadow: 0rem 0rem 0.875rem rgba(0, 0, 0, 0.25);
}

.login-btn svg {
  margin-right: 0.625rem;
}

.abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.logout-page {
  position: absolute !important;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #f6f7ff;
}

.dark .logout-page {
  background-color: #262c41;
}

.back-btn {
  cursor: pointer;
  position: relative;
  text-align: center;
  width: max-content;
  margin: auto;
  margin-top: 1rem;
  font-size: 1.2rem;
}

.back-btn::after {
  position: absolute;
  bottom: -0.05rem;
  height: 1px;
  width: 100%;
  left: 0;
  content: "";
  background-color: #000;
}

.logged {
  font-size: 2rem;
}
.bold {
  cursor: pointer;
  text-decoration: underline;
}

.dn {
  display: none;
}
