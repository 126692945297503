.subscribe {
  overflow: hidden;
  position: relative;
}
.subscribe .blurImg {
  object-fit: cover;
  height: 100vh;
  width: 100vw;
  filter: blur(10px);
  -webkit-filter: blur(10px);
}

.subscribePlan {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
