.header {
  color: #323a52;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 20px;
}

.row {
  display: flex;
  align-items: flex-end;
}

.row button {
  margin-left: 1rem;
  white-space: nowrap;
  border-radius: 5px;
  background: #767bfa;
  font-family: inherit;
  min-height: 29px;
  padding: 6px 18px;
  border: none;
  outline: none;
  color: #f6f7ff;
  font-weight: 500;
  cursor: pointer;
  font-size: 13px;
}

.divider {
  background: #d5d5d5;
  height: 2px;
  width: 100%;
  margin: 20px 0;
}

.staffItem {
  display: grid;
  grid-template-columns: 1.5fr 1fr auto;
  padding: 10px;
}

.staffItem span {
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.systemManagement {
  grid-column: span 2;
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerText {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.subHeader {
  color: #323a52;
  font-size: 15px;
  font-style: normal;
  margin-top: 18px;
  font-weight: 500;
  padding-left: 1.5rem;
}

.button {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: blue;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: darkblue;
}

.button:disabled,
.button.disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 0.7;
}

.iconWrapper {
  padding-right: 10px;
}

.icon {
  width: 20px;
  height: 20px;
}

.left {
  flex-basis: 66.6%;
  gap: 1.5rem 2.5625rem;
  display: grid;
  grid-template-rows: auto auto;
  height: max-content;
}

.icon {
  width: 65px;
  height: 65px;
}

.iconButtonWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  background-color: #f6f7ff;
  border-radius: 5px;
}

.passcode {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;
}

.verticalDivider {
  width: 2px;
  height: 90px;
  background-color: #d5d5d5;
}

.container {
  display: grid;
  margin-top: 1.5rem;
}

.gridHeader,
.gridRow {
  display: grid;
  grid-template-columns: 1fr 1.25fr 1.25fr 140px;
  margin: 6px 0;
  padding: 0 0 0 16px;
  font-size: 15px;
  align-items: center;
}

.gridHeader {
  padding: 0 6px 0 22px;
}

.gridRow {
  border-radius: 5px;
  border: 1px solid #ebebeb;
  background: #f6f7ff;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.15);
  min-height: 43px;
}

.gridHeader > div {
  font-size: 15px;
  font-weight: 500;
}

.emptyData {
  text-align: center;
  color: #abafd1;
  font-weight: 600;
  font-size: 20px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gridContent {
  max-height: 300px;
  overflow-y: auto;
  padding: 0 6px;
}

.table {
  display: flex;
  flex-direction: column;
}

.tableRow {
  display: grid;
  grid-template-columns: 1fr 0.6fr 1.5fr 1.5fr 0.75fr 0.9fr;
  gap: 0.425rem;
}

.cell {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tableRow:first-child {
  background-color: transparent;
}

.tableRow:last-child .cell {
  border-bottom: none;
}

.cell:last-child {
  border-right: none;
}

.txSummary {
  grid-column: span 2;
  padding: 20px;
}

.headerRow {
  font-weight: 500;
}

.valuesRow {
  height: 43px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #ebebeb;
  background: #f6f7ff;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.15);
  margin: 5px 0;
}

.headerRow,
.valuesRow {
  padding-left: 1rem;
  font-size: 15px;
}

.headerRow {
  padding: 0 16px;
  padding-left: 18px;
  padding-right: 6px;
}

.tableContent {
  max-height: 306px;
  overflow-y: auto;
  padding: 6px;
}

.valuesRow > div:last-child,
.valuesRow > div:nth-child(5),
.headerRow > div:last-child,
.headerRow > div:nth-child(5) {
  text-align: center;
}

.valuesRow > div:last-child {
  color: #767bfa;
  text-align: center;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 400;
}

.card {
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
}

.icon {
  width: 60px;
  height: 60px;
  margin-right: 20px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.value {
  font-size: 22px;
  font-weight: 600;
}

.label {
  font-size: 16px;
}

.summaryCardWrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.filterWrapper {
  max-width: 180px;
  margin-left: auto;
  margin-bottom: 16px;
}
