.header {
  color: #323a52;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.9375rem;
}

.header span {
  color: #95979f;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 300;
  margin-left: 0.3125rem;
  line-height: normal;
}

.ticketSold {
  height: 100%;
}

.progressbarGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: center;
  margin-top: 1.25rem;
  gap: 1.5rem;
}

.styledProgressbar {
  width: 6.875rem;
  height: 6.875rem;
}

.box {
  padding: 1.5rem 1.9375rem;
  border-radius: 0.9375rem;
  height: 520px;
  overflow: auto;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem 0rem rgba(0, 0, 0, 0.15);
  height: 465px;
  overflow-y: auto;
}

.content {
  min-height: 10.875rem;
}

.content,
.fairnessSystemSetting {
  height: max-content;
}

.fairnessSystemSetting {
  margin-bottom: 1.5625rem;
}

.fairnessSystemSetting .box {
  padding: 1rem 1.5rem;
}

.progressbarContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.progressbarText {
  margin-top: 0.25rem;
}

.progressbarText span:first-child {
  color: #323a52;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 0.125rem;
}

.progressbarText span:last-child {
  color: #abafd1;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transform: translateY(-10px);
  display: inline-block;
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
}

.progressbarDetails {
  margin-top: 0.9375rem;
}

.progressbarType {
  color: #323a52;
  text-align: center;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.progressbarPrice {
  color: #abafd1;
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@keyframes progress-animation {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 100;
  }
}

.progressbar.animated {
  animation: progress-animation 1s ease-in-out;
}

.checkboxGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-flow: column;
  grid-template-rows: repeat(5, 1fr);
  grid-gap: 0.25rem;
}

.captchaGrid {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  margin-top: 1.25rem;
  grid-gap: 1rem;
}

.captchaGrid.headers,
.ipGrid.headers,
.vipGrid.headers {
  font-weight: 500;
}

.captchaGrid > div {
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.action {
  display: flex;
  align-items: center;
}

.action img {
  height: 1.25rem;
  width: 1.25rem;
  cursor: pointer;
}

.action img:first-child {
  margin-right: 0.625rem;
}

.themeSelect {
  max-width: 19.25rem;
  width: 100%;
  margin-right: 1.375rem;
}

.bannerImagePreview {
  height: 9.875rem;
  object-fit: contain;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  margin-bottom: 1.25rem;
  margin-top: 2.1875rem;
}

.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.bannerSection {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  max-height: 24rem;
}

.info {
  color: #323a52;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.twoColumnGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.ipGrid {
  display: grid;
  grid-template-columns: 1fr 0.4fr;
  margin-top: 1.5rem;
}

.vipGrid {
  display: grid;
  grid-template-columns: 1.25fr 2fr 4.375rem;
  align-items: center;
  margin-top: 1.5rem;
}

.countryItem {
  display: flex;
  align-items: center;
}

.countryItem img {
  height: 1.25rem;
  margin-right: 1rem;
}

.progressBar {
  min-height: 10.875rem;
}

.vipGrid .userName {
  color: #323a52;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.vipGrid .userEmail {
  color: #323a52;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.vipItem {
  display: flex;
  align-items: center;
  gap: 0.625rem;
}

.maxPurchaseAmount {
  text-align: center;
}

.userName,
.userEmail {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.userDetails {
  display: grid;
}

.boldText {
  color: #323a52;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1.25rem;
}

.smHeight {
  max-height: 12.5rem;
}

.smHeight .header {
  width: 100%;
}

.smHeight .box {
  width: 100%;
  padding-bottom: 1.25rem;
}

.accountCreationDate {
  margin-top: 1rem;
}

.accountCreationDate .boldText {
  margin-top: 0.5rem;
}

.instagram {
  max-height: 26rem;
}

.createDate {
  color: #323a52;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.countryList {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  user-select: none;
}

.countryScroll {
  border-radius: 0.3125rem;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.5625rem 0rem rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
  max-height: 15rem;
  overflow: auto;
  padding: 0.5rem 0;
}

.pointer {
  cursor: pointer;
  padding: 0.25rem 1rem;
}

.selected {
  background: rgba(118, 123, 250, 0.25);
}
