.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3.125rem 0;
}

.container > div {
  flex-basis: 33.3%;
}

.stat {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 70px 1fr;
  width: max-content;
  margin: auto;
  grid-gap: 0.625rem;
}

.stat img {
  width: 4.0625rem;
  height: 4.0625rem;
}

.header {
  font-size: 1.375rem;
  font-weight: 600;
}

.subtext {
  font-size: 0.9375rem;
  font-weight: 400;
}

.vr {
  height: 4.375rem;
  border-right: 2px solid #d5d5d5;
}
