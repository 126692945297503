.headersection {
  position: relative;
  padding: 0 1rem;
}

.headersection::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 7.3125rem;
  bottom: -0.25rem;
  left: 1rem;
  background-size: contain;
  background-repeat: no-repeat;
}

.dark .headersection::before {
  background-image: url("../../assets/dashdark.png");
}

.smtext {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  padding-left: 0.25rem;
}

.px25 {
  padding-left: 1.25rem;
  margin-top: 1.5625rem;
}

.label {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  margin-bottom: 0.75rem;
}

.wrap {
  grid-gap: 1.5625rem 3rem;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(4, 14.0625rem);
}

.selectContainer {
  max-width: 14.0625rem;
  width: 100%;
  flex-shrink: 0;
}

.twogrid {
  display: grid;
  grid-template-columns: 1fr;
}

.twoGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
}

.end {
  justify-content: flex-end;
}

@media (max-width: 1700px) {
  .wrap {
    grid-gap: 1.5rem 2.125rem;
  }
}

.btncontainer {
  grid-column: span 4;
  margin-top: 1.875rem;
  justify-content: flex-end;
}

.w100 {
  width: 100%;
}

.abs {
  position: absolute;
  right: 2rem;
  top: -3rem;
}

.mtCustom {
  margin-top: 7rem;
}

.rel {
  position: relative;
}

.cp {
  cursor: pointer;
}

.visible {
  transform-origin: center;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.px15 {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem rgb(0 0 0 / 15%);
  border-radius: 0.9375rem;
  padding: 0.8125rem 0;
  padding-right: 1rem;
}

.settingsContainer {
  max-height: 85vh;
  overflow-y: auto;
  padding-bottom: 1rem;
}

.dark .px15 {
  background: #353c53;
}

.pr15 {
  padding-right: 1.5rem;
}

.pad5 {
  margin-right: 15px;
}
