.container {
  border-radius: 0.3125rem;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.5625rem 0rem rgba(0, 0, 0, 0.15);
  width: max-content;
  position: relative;
}

.search {
  display: flex;
  align-items: center;
  min-height: 1.75rem;
  padding: 0 0.625rem;
}

.search img {
  margin-right: 0.625rem;
  max-width: 14.25rem;
}

.search input {
  border: none;
  background: none;
  line-height: normal;
  color: #000;
  width: 100%;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
}

.search input::placeholder {
  color: #abafd1;
}
