.tooltipIcon {
  margin-left: 0.25rem;
  display: inline-block;
}

.tooltipContent {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  padding: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: left;
  bottom: 120%;
  max-width: 500px;
  left: 20%;
}
