.container {
  display: flex;
  grid-gap: 25px;
}

.twoGrid {
  display: grid;
  grid-gap: 0 3.125rem;
  grid-template-columns: 1fr 1fr;
}

.setingsGrid {
  display: grid;
  grid-gap: 1.875rem 3.125rem;
  grid-template-columns: 1fr 1fr;
}

.btns {
  padding-left: 3.125rem !important;
  padding-right: 0.9375rem !important;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.box {
  border-radius: 0.9375rem;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem 0rem rgba(0, 0, 0, 0.15);
  padding-top: 1.875rem;
}

.graph {
  padding: 0 1rem;
}

.left {
  flex-basis: 66.6%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.right {
  flex-basis: 33.4%;
}

.graph {
  border-top: 1px solid #dfe0e7;
  margin: 0 1rem;
}

.graph .header {
  padding-top: 1.3125rem;
  color: #323a52;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.graph .total {
  color: #323a52;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1.5625rem;
}

.graph .total span {
  color: #a9aab1;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.scroll {
  padding: 4px;
  max-height: 550px;
  overflow-y: auto;
  overflow-x: visible;
}

.selectSaleName {
  max-width: 230px;
  width: 100%;
}

.rowContainer {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.fairness .twoGrid:not(:first-child) {
  margin-top: 1.5rem;
}
