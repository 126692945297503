.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.listContainer {
  height: 30rem;
  overflow-y: auto;
  padding-bottom: 10px;
  border-bottom: 1px solid #d5d5d5;
}

.totalsContainer {
  height: max-content;
  overflow-y: auto;
  width: 100%;
  padding: 5px 10px;

  display: flex;
  justify-content: center;
}

.statsWrapper {
  width: min-content;
}

.statContainer {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin: 1.25rem auto 2.5rem auto;
}

.hr {
  width: 100%;
  border-top: 1px solid #d5d5d5;
}
