.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.loader {
  width: max-content;
  margin: auto;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  min-width: 9.0625rem;
}

.headers,
.values {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1.5fr 1.5fr;
  align-items: center;
  padding: 0 1.25rem;
  grid-column: 1/3;
}

.headers {
  color: #323a52;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.5rem;
}

.gridData {
  padding: 2.8125rem 1.25rem;
}

.values {
  border-radius: 0.3125rem;
  border: 0.0625rem solid #ebebeb;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem 0rem rgba(0, 0, 0, 0.15);
  min-height: 2.6875rem;
  color: #323a52;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0.3475rem;
}
