.imageContainer {
  background-color: #777ad4;
  border-radius: 100px;
  width: 3.75rem;
  height: 3.75rem;
  margin-right: 1.25rem;
}

.imageContainer img {
  width: 3.75rem;
  height: 3.75rem;
  object-fit: cover;
  border-radius: 50px;
}

.uploadWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16.5px 14px;
  border-radius: 7px;
  background-color: #f6f7ff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  width: 14.0625rem;
}

.uploadText {
  color: #767bfa;
  font-weight: 300;
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
