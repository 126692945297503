.summary {
  height: 90vh;
  display: grid;
  grid-gap: 1.25rem 0.9375rem;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.headersection {
  position: relative;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-bottom: 0.625rem;
  padding: 0.8125rem 1.25rem;
  padding-bottom: 0;
}

.walletPage .box,
.cashoutgrid,
.bankAccounts,
.recentActivities {
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.topRight {
  display: grid;
  grid-template-rows: 10.425rem 1fr;
  grid-gap: 1.0625rem;
}

.span2 {
  grid-column: span 2;
}

.span3 {
  grid-column: span 3;
}

.note {
  font-weight: 300;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  text-align: center;
  margin-left: 0.425rem;
  color: #767bfa;
  padding-right: 0.5rem;
}

.wbpy {
  padding: 0.125rem 0;
}
