@import url("https://fonts.googleapis.com/css2?family=Questrial&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

.page {
  grid-column: 1/3;
  font-family: "Questrial", sans-serif;
  width: 100%;
}

.banner {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  color: #fff;
  padding-left: 3.5625rem;
  padding-right: 5.625rem;
  height: 12rem;
  position: relative;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.rightSection {
  display: flex;
  justify-content: flex-end;
  gap: 8.6875rem;

  svg path {
    stroke: #000;
  }
}

a.description {
  border-radius: 0.9375rem;
  text-transform: uppercase;
  height: 2.8125rem;
  width: 8.5625rem;
  height: 2.8125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000 !important;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 300;
}

.logo {
  width: 9.375rem;
  height: auto;
  cursor: pointer;
}

.textContainer {
  display: flex;
  align-items: center;
  gap: 3.3125rem;

  a {
    text-decoration: none;
  }
}

.hero {
  position: relative;
  justify-content: center;
  align-items: center;
  height: 54rem;
  width: 100%;
  background-size: cover;
}

.heroTextContainer {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10vw;
}

.primaryText {
  color: #fff;
  font-size: 5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: max-content;
  max-width: 18ch;
  position: relative;
  margin-bottom: 1.5rem;
}

.secondaryText {
  color: #fff;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: max-content;
  position: relative;
  max-width: 24ch;
}

.section {
  height: 13.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  text-align: center;
  font-size: 5rem;
  margin: 0 3.5625rem;
  text-transform: uppercase;
}

.border {
  width: 85%;
  height: 0.5px;
  background-color: #656565;
}

.events {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 3.125rem;
  justify-content: center;
  padding: 0 4.8125rem;
}

.eventImage {
  object-fit: cover;
  height: 16.6875rem;
  border-radius: 0.625rem;
  width: 100%;
}

.eventDescription {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.eventDetailsContainer {
  max-width: 40.125rem;
  width: 100%;
}

.eventName {
  margin-bottom: 0.625rem;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 400;
  color: #656565;
  margin-top: 1.25rem;
}

.eventDetails {
  color: #656565;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

.eventPrice {
  color: #000;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 1.5625rem;
}

.viewDetailsBtn {
  border-radius: 3.0625rem;
  border: 2px solid #fff;
  width: max-content;
  max-width: 21rem;
  width: 100%;
  height: 3.4375rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 3.125rem;
  padding-right: 2.0625rem;
  color: #fff;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: auto;
  cursor: pointer;
}

.event {
  display: flex;
  flex-direction: column;
  max-width: 25rem;
  width: 100%;
  cursor: pointer;
}

.products {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 12.8125rem;
  gap: 3.125rem 9.625rem;
  justify-content: center;
  align-items: center;
}

.product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 18.75rem;
  cursor: pointer;

  .viewDetailsBtn {
    border: 2px solid #000;
    color: #000;

    svg path {
      stroke: #000;
    }
  }
}

.productImage {
  width: 18.75rem;
  height: 16.75rem;
  object-fit: contain;
}

.productDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 40.125rem;
  width: 100%;
}

.productName {
  color: #656565;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 1.5625rem;
  text-align: center;
  margin-top: 1.5625rem;
}

.productPrice {
  color: #000;
  text-align: center;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 1.5625rem;
  line-height: normal;
}

.footer {
  background-color: #ffcee3;
  padding: 0 9.1875rem;
  padding-left: 15rem;
  padding-top: 1.9375rem;
  padding-bottom: 1.5625rem;
  min-height: 21rem;
  display: flex;
  flex-direction: column;
  position: relative;
}

.footerContainer {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
  margin: auto;
  justify-content: space-between;
  gap: 3.125rem;
  height: 100%;
  width: 100%;
}

.footerSection {
  flex: 1.25;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footerHeading {
  font-size: 0.9375rem;
  font-style: normal;
  padding-bottom: 1.875rem;
  border-bottom: 0.5px solid #656565;
  text-transform: uppercase;
  font-weight: 400;
}

.footerList {
  list-style: none;
  padding: 0;
  li {
    display: block;
    margin-bottom: 1.5625rem;
    color: #000;
    font-size: 1.125rem;
  }
}

.socialLink {
  display: block;
  text-transform: uppercase;
  margin-top: 1.5625rem;
  color: #656565;
  font-size: 0.9375rem;
}

.copyright {
  text-align: center;
  margin-top: auto;
  color: #000;
  padding-top: 1.5rem;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 1.25rem;
  justify-content: center;

  svg {
    width: 1.5rem;
    path {
      fill: #000;
    }
  }
}

.cartContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.footerLogo {
  height: 10.3125rem;
  object-fit: cover;
}

.footerLogoContainer {
  flex: 2;
  display: flex;
  justify-content: flex-end;
}
