.headerColumns,
.itemColumns {
  grid-template-columns: repeat(6, 1fr);
}

.itemRows {
  height: 100%;
  overflow-y: auto;
  max-height: 190px;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.txId {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
