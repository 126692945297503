.backgroundBar {
  max-width: 61.75rem;
  width: 100%;
  display: flex;
  align-items: center;
}

.circle {
  height: 1.75rem;
  width: 1.75rem;
  background-color: #abafd1;
  border-radius: 50%;
  aspect-ratio: 1;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.step {
  height: 0.625rem;
  width: 33.3%;
  background-color: #abafd1;
  position: relative;
  z-index: 0;
  transform: translateX(-0.25rem);
  -webkit-transform: translateX(-0.25rem);
  -moz-transform: translateX(-0.25rem);
  -ms-transform: translateX(-0.25rem);
  -o-transform: translateX(-0.25rem);
}

.circle {
  margin-left: -1.5rem;
}

.circle:first-child {
  margin-left: 0;
}

.circleText {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: translateY(-2.5rem);
  -webkit-transform: translateY(-2.5rem);
  -moz-transform: translateY(-2.5rem);
  -ms-transform: translateY(-2.5rem);
  -o-transform: translateY(-2.5rem);
  font-weight: 600;
  font-size: 1rem;
  line-height: 2.0625rem;
}

.progressBarText {
  color: #323a52;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 4rem;
}

.progressBarText span {
  font-weight: 600;
}
