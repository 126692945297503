.inputWrapper {
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}

.searchResults {
  margin-top: 0.625rem;
  padding: 0 1.25rem;
  max-height: 40rem;
  overflow-y: auto;
}

.ticket {
  display: flex;
  align-items: stretch;
  cursor: pointer;
  padding: 0.625rem;
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
}

.ticket:hover {
  background: #dfdfdf;
}

.dark .ticket:hover {
  background: #3b435e;
}

.ticketImg {
  max-width: 7rem;
  max-height: 7rem;
  width: 100%;
  overflow: hidden;
  object-fit: fill;
  border-radius: 0.625rem;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  -o-border-radius: 0.625rem;
  margin-right: 0.625rem;
}

.ticketTitle {
  font-size: 1.125rem;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ticketDesc {
  font-size: 0.925rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ticketDate {
  font-size: 0.9375rem;
  font-weight: 500;
}

.ticketContainer {
  height: 100%;
}

.ticketInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  width: 100%;
}
