.formControl {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  margin: 0px !important;
}

.dark .formControl {
  background: #3b435e;
}

.menuitem {
  font-family: "Poppins";
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  text-align: center;
  justify-content: center;
  color: #abafd1;
}

.dark .menuitem {
  color: #f6f7ff;
}

.menuitem:hover {
  background-color: rgba(118, 123, 250, 0.15) !important;
}

.menuitem.focusVisible {
  background-color: rgba(118, 123, 250, 0.15) !important;
}

.parentSelect img {
  height: 16px;
}

.smSelect {
  display: flex !important;
  align-items: center !important;
  border-radius: 5px;
  height: 25px !important;
  padding: 0.125rem 0.8125rem !important;
  padding-right: 3rem !important;
}

.select {
  padding: 0.875rem 1.375rem !important;
  padding-right: 3.5rem !important;
}

.labelWithIcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.labelWithIcon img {
  margin-left: 0.625rem;
}

.select .labelWithIcon {
  width: 14ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.labelWithIcon img {
  display: none;
}

.menuitemWithIcon img {
  display: block;
}

.uploadSeatMap {
  color: #767bfa;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
  text-decoration-line: underline;
  position: absolute;
  z-index: 1;
  width: calc(100% - 3.5rem);
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #f6f7ff;
}
