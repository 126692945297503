@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

.page {
  grid-column: 1/3;
  background-color: #fff;
  font-family: "Rubik", sans-serif;
  width: 100%;
}

.strip {
  height: 3.75rem;
}

.banner {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  background-color: #fff;
  padding-left: 8rem;
  padding-right: 8rem;
  height: 10.3125rem;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.rightSection {
  display: flex;
  justify-content: flex-end;
  gap: 4.5rem;

  svg path {
    stroke: #000;
  }
}

a.description {
  border-radius: 0.9375rem;
  border: 1px solid #000;
  height: 2.8125rem;
  width: 8.5625rem;
  height: 2.8125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000 !important;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 300;
}

.logo {
  width: 9.375rem;
  height: auto;
  cursor: pointer;
}

.textContainer {
  display: flex;
  align-items: center;
  gap: 3.75rem;

  a {
    text-decoration: none;
  }
}

.hero {
  position: relative;
  display: grid;
  justify-content: center;
  align-items: center;
  height: 54rem;
  grid-template-columns: 1fr 1fr;
}

.heroTextContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.heroText {
  position: relative;
  min-width: 49rem;
}

.primaryText {
  color: #fff;
  font-size: 5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: max-content;
  max-width: 15ch;
}

.secondaryText {
  color: #fff;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: max-content;
  max-width: 20ch;
}

.heroImageContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.heroImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section {
  height: 13.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  text-align: center;
  font-size: 5rem;
  padding: 0 3.5625rem;
}

.events {
  position: relative;
}

.eventImage {
  flex: 1;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.eventDescription {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.eventDetailsContainer {
  max-width: 40.125rem;
  width: 100%;
}

.eventName {
  font-size: 2.1875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 1.3125rem;
}

.eventDetails {
  font-size: 1.5625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.eventPrice {
  font-size: 2.1875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 1.875rem;
}

.viewDetailsBtn {
  border-radius: 3.0625rem;
  border: 1px solid transparent;
  width: max-content;
  max-width: 21rem;
  width: 100%;
  height: 3.4375rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 3.125rem;
  padding-right: 2.0625rem;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: auto;
  cursor: pointer;
}

.event {
  display: flex;
  height: 39.6875rem;

  &:nth-child(even) {
    flex-direction: row-reverse;
  }
}

.products {
  display: flex;
  flex-wrap: wrap;
  padding-top: 8.3125rem;
  padding-bottom: 12.8125rem;
  gap: 3.125rem 15.3125rem;
  justify-content: center;
  align-items: center;
  position: relative;
}

.product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 18.75rem;

  .viewDetailsBtn {
    border: 2px solid #000;
    color: #000;
  }
}

.productImage {
  width: 18.75rem;
  height: 18.75rem;
  border: 0.125rem solid #000;
  object-fit: contain;
}

.productDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 40.125rem;
  width: 100%;
}

.productName {
  font-size: 2.1875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 1.5625rem;
}

.productDescription {
  font-size: 1.5625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 1.5625rem;
  margin-bottom: 1.5625rem;
}

.productPrice {
  font-size: 2.1875rem;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 1.5625rem;
  line-height: normal;
}

.footer {
  padding: 0 7.1875rem;
  padding-top: 3.9375rem;
  padding-bottom: 1.5625rem;
  min-height: 31.6875rem;
  display: flex;
  flex-direction: column;
  position: relative;
}

.footerContainer {
  display: flex;
  align-items: center;
  max-width: 64.125rem;
  margin: auto;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.footerSection {
  flex: 1.25;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footerHeading {
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 1.25rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.footerList {
  list-style: none;
  padding: 0;
  li {
    display: block;
    margin-bottom: 1.5625rem;
    color: #fff;

    font-size: 1.125rem;
  }
}

.socialLink {
  display: block;
  margin-bottom: 1.5625rem;
  color: #fff;
  font-size: 1.125rem;
}

.copyright {
  text-align: center;
  color: #888;
  margin-top: auto;
  color: #fff;
  padding-top: 1.5rem;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 2.25rem;
  justify-content: center;

  svg path {
    fill: #fff;
  }
}

.cartContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.footerLogo {
  height: 10.3125rem;
  object-fit: cover;
}

.footerLogoContainer {
  flex: 1;
  margin-left: -8.125rem;
  display: flex;
  justify-content: center;
}
