.label {
  font-weight: 600;
  font-family: "Poppins";
  line-height: 1.125rem;
}

.msg {
  font-size: 0.875rem;
  font-family: "Poppins";
  line-height: 1.125rem;
  margin-top: 0.125rem;
}
