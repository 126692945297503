.colorPickerContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.colorPicker {
  border-radius: 7px;
  background: #f6f7ff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1) inset;
  display: flex;
  align-items: center;
  position: relative;
}

.colorLabel {
  color: #323a52;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 10px;
}

.colorPreview {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 7px;
}

.colorInput {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  margin-right: 10px;
}

.text {
  color: #323a52;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-left: 24px;
  cursor: pointer;
  text-decoration-line: underline;
}
