.headerColumns,
.itemColumns {
  grid-template-columns: 1fr 0.9fr 0.8fr 0.7fr 0.8fr 1.2fr 0.8fr 0.8fr;
}

.itemRows {
  height: 100%;
  overflow-y: auto;
}

.activitiesImg {
  margin-left: auto;
  justify-self: flex-end;
  padding-right: 1rem;
}

.activitiesImg img:first-child {
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}
