.container {
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: #f6f7ff;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.15);
  width: max-content;
  height: 25px;
  padding: 2px 13px;
  max-width: 145px;
}

.container input {
  color: #abafd1;
  font-size: 15px;
  font-weight: 400;
}

.container input::placeholder {
  color: #abafd1;
}
