.summary {
  display: grid;
  grid-gap: 1.875rem 2.5rem;
  grid-template-rows: 19.5625rem 26.25rem;
  grid-template-columns: 1fr 1fr 1fr;
}

.headersection {
  position: relative;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-bottom: 0.625rem;
  padding: 0.8125rem 1.25rem;
  padding-bottom: 0;
  padding-top: 1.875rem !important;
}

.gridContainer {
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.gridHeaders,
.gridItems {
  display: grid;
  grid-gap: 0.375rem;
}

.gridHeaders {
  width: calc(100% - 1.875rem);
  margin: auto;
  padding-left: 1.375rem;
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.375rem;
}

.gridItems {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  padding: 0.8125rem 0;
  padding-left: 1.375rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  width: calc(100% - 1.875rem);
  margin: auto;
  margin-bottom: 0.5rem;
  margin-top: 0.625rem;
}
