.container {
  margin: 1.25rem auto;
}

.headerColumns,
.itemColumns {
  grid-template-columns: 0.8fr 1fr 0.8fr 1fr 0.75fr;
}

.itemRows {
  min-height: 30rem;
}

.sortIcon {
  display: inline-block;
  transform: translateX(4px) translateY(4px);
  -webkit-transform: translateX(4px) translateY(4px);
  -moz-transform: translateX(4px) translateY(4px);
  -ms-transform: translateX(4px) translateY(4px);
  -o-transform: translateX(4px) translateY(4px);
}

svg.reverseSortIcon {
  transform: rotateZ(180deg);
}
