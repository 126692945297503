.searchBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 5px;
  background: #f6f7ff;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.15);
  height: 1.75rem;
  padding: 0 0.625rem 0 0.75rem;
}
.h40 {
  height: 2.5rem;
}

.dark .searchBtn {
  background: #353c53;
  box-shadow: 0rem 0rem 0.5625rem rgba(0, 0, 0, 0.15);
}

svg {
  cursor: pointer;
}

input {
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-right: 0.3125rem;
}

.dark input {
  color: #f6f7ff;
}

.searchBtn input::placeholder {
  color: #abafd1;
}

.dark .searchBtn input::placeholder {
  color: #5a5d74 !important;
}
