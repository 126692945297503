.summary {
  display: flex;
  grid-gap: 1.5rem;
  grid-template-rows: 11.465rem 26.5625rem 17.465rem;
  grid-template-columns: 1fr 1fr 1fr;
}

.left {
  flex-basis: 66.6%;
  gap: 1.5rem 2.5625rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 11.465rem 27.5625rem auto;
}

.right {
  flex-basis: 33.4%;
  grid-gap: 1.25rem 0.9375rem;
  display: grid;
  grid-template-columns: 1fr;
}

.headersection {
  position: relative;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-bottom: 0.625rem;
  padding: 0.8125rem 1.25rem;
  padding-bottom: 0;
}

.span2 {
  grid-column: span 2;
}

.span3 {
  grid-column: span 3;
}

.totalScannedGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 2.0625rem;
  align-items: start;
  height: 100%;
  overflow-y: auto;
  padding: 0.9875rem;
}

.pad {
  padding-top: 2.5rem;
  min-height: 0;
  overflow: auto;
  padding-bottom: 2.5rem;
}
