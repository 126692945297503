.sidebarContainer {
  height: 100%;
  position: sticky;
  top: 0;
}

.sidebar {
  background-color: #323a52;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 1.875rem;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: none;
}

.dark .sidebar {
  background-color: #262c41;
}

.sidebar a,
.sidebar div {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-weight: normal;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding: 0 1.375rem;
  color: #abafd1;
  white-space: nowrap;
  transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
}

.sidebar a:hover,
.sidebar div:hover {
  color: #ffffff;
}

.sidebar a,
.sidebar div {
  margin-top: 3.125rem;
}

.sidebar a span {
  display: flex;
  align-items: center;
  width: 3rem;
}

.logo {
  padding-left: 1.375rem;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.logo img {
  width: 9.0625rem;
}

.logo span {
  color: #ffffff;
  font-size: 1.25rem;
  padding-top: 1.125rem;
}

.sidebar .activeLink {
  color: #ffffff;
}

.sidebar .activeLink::after {
  position: absolute;
  content: "";
  right: 0rem;
  width: 0.75rem;
  height: 1rem;
  background-image: url("../../assets/polygon.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.dark .sidebar .activeLink::after {
  background-image: url("../../assets/polygon1.svg");
}

.activeLink .activeWhite {
  fill: #fff;
}
