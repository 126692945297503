.header {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
}

.header img {
  cursor: pointer;
}

.input {
  max-width: 14.0625rem;
  margin: auto;
}

.imageSideInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}
.formImageContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  gap: 1.25rem;
}

.threeGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}

.addEventModal .threeGrid {
  padding: 0;
  margin-top: 0.625rem;
}

.oneGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.625rem 1.25rem;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}

.label {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-bottom: 0.625rem;
  display: flex;
  align-items: center;
  position: relative;
}

.note {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-top: 0.425rem;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: start;
  height: 100%;
}

.jus-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.jus-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.uploaded {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1.265rem;
}

.savedAlert {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding-left: 1.25rem;
  padding-right: 0.625rem;
}

.fees {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  font-size: 0.9375rem;
}

.total {
  font-size: 1.125rem;
}

.readOnly {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}

.dark .readOnly {
  color: #f6f7ff;
  background: #3b435e;
}

.span2 {
  grid-column: span 2;
}

.span3 {
  grid-column: span 3;
}

.breakup {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
}

.breakup .fees {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  margin-top: 1.425rem;
}

.gridImg {
  max-width: 9.8375rem;
  height: auto;
  object-fit: cover;
  border-radius: 0.6125rem;
}

.jc-ce {
  display: flex;
  align-items: center;
  justify-content: center;
}

.jc-se {
  justify-content: space-evenly;
}

.jc-sb {
  justify-content: space-between;
}

.btns {
  margin: 1.25rem 0 0.625rem 0;
  padding: 0 1.25rem;
}

.px20 {
  padding: 0 1.25rem;
}

.pt10 {
  padding-top: 0.625rem;
}

.tableContainer {
  margin: 0 0.625rem;
}

.span24 {
  grid-column: 2/4;
}

.green {
  color: #12c519;
}

.mb20 {
  margin-bottom: 1.25rem !important;
}

.mb10 {
  margin-bottom: 0.625rem !important;
}

.missing-container {
  padding: 0 1.25rem;
}

.lgHeader {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}

.lgHeader img {
  cursor: pointer;
}

.searchInputCm {
  max-width: 14.0625rem;
  margin: 1.25rem 0;
}

.sm-headers-grid {
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #323a52;
}

.smHeadersGrid,
.smItemGrid {
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.3fr 0.75fr 2rem;
  align-items: center;
}

.smItemGrid img {
  height: 2.5rem;
  border-radius: 0.3125rem;
  object-fit: contain;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
}

.smItemGrid {
  background: #f6f7ff;
  box-shadow: 0rem 0rem 1.0625rem rgb(0 0 0 / 10%);
  padding: 0.3125rem 0;
  margin: auto;
  padding-left: 1.3125rem;
  padding-right: 0.625rem;
  border-radius: 0.4375rem;
  -webkit-border-radius: 0.4375rem;
  -moz-border-radius: 0.4375rem;
  -ms-border-radius: 0.4375rem;
  -o-border-radius: 0.4375rem;
  margin-bottom: 0.3125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  position: relative;
  border: 0.0625rem solid transparent;
  margin-bottom: 0.3125rem;
}

.dark .smHeadersItem {
  background: #3b435e;
}

.smItems {
  padding-top: 0.3125rem;
  max-height: 28.75rem;
  overflow-y: auto;
  padding: 0.5rem;
}

.dark .smItemGrid {
  background: #3b435e;
}

.colGrid {
  display: flex;
  flex-direction: column;
}

.smBold {
  font-weight: 500;
}
.listItem {
  background: #f6f7ff;
  padding-left: 1.1875rem;
  padding-right: 0.375rem;
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  border-radius: 0.4375rem;
  grid-gap: 0.3125rem;
  min-height: 3.125rem;
  font-weight: 300;
  font-size: 0.9375rem;
  border: 0.0625rem solid transparent;
  transition: all 0.2s ease-in-out;
  line-height: 1.375rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  margin-bottom: 0.3125rem;
}
.uploadTicketContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 1.25rem;
}
.uploadTicketBox {
  display: flex;
  gap: 0.625rem;
  flex-direction: column;
}

.dark .uploadTicketBox {
  background: #3b435e;
}
.btnContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
.uploadTicketInput {
  padding: 0.3rem 1rem;
  line-height: 1rem;
  font-size: 0.675rem;
  height: 2rem;
  max-width: 12rem;
}

.dark .uploadTicketInput {
  background: #383f57;
}

.addTicketModal .header {
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 1.5625rem;
  line-height: 2.375rem;
}

.addTicketModal .subText {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  color: #767475;
}

.addTicketModal .twoGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;
  margin: 0.625rem 0;
}

.addTicketModal .mapImg {
  width: 100%;
  object-fit: contain;
  height: 27.75rem;
}

.gap0 {
  grid-gap: 0 !important;
  gap: 0 !important;
}

.addTicketModal .smHeader {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #181818;
}

.dark .addTicketModal .smHeader {
  color: #f6f7ff;
}

.addTicketModal .smText {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #181818;
}

.dark .addTicketModal .smText {
  color: #f6f7ff;
}

.add-ticket-details-grid .headersGrid,
.add-ticket-details-grid .values-grid {
  display: grid;
  grid-template-columns: 1fr 2.5fr 1.25fr 1.25fr 2fr;
  gap: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0 0.25rem;
  text-align: center;
}

.border {
  width: 100%;
  height: 0.0625rem;
  background-color: #fff;
  margin: 1.5rem 0;
}

.dark .border {
  background-color: #5a5d74;
}

.add-ticket-details-grid .values-grid {
  box-shadow: 0rem 0rem 1.0625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 0.625rem 0;
}

.values-grid > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bodyGrid {
  max-height: 22rem;
  overflow-y: auto;
  padding: 0.5rem 0.25rem;
}

.blue-btn {
  cursor: pointer;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration-line: underline;
  color: #767bfa;
  -moz-text-decoration-line: underline;
}

.addTicketModal .label {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 0.75rem;
}

.addTicketModal .label span {
  width: 1rem;
  line-height: 0.75rem;
  height: 1rem;
  font-size: 0.75rem;
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.addTicketModal .label span img {
  width: 100%;
}

.mapImg path.clickable {
  cursor: pointer;
}

/* Add Event */

.banner {
  min-height: 11rem;
  background-image: url("../../assets/bannerImg.webp");
  background-size: cover;
  background-position-y: -3.125rem;
  margin: auto 1.875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  border-radius: 0.9375rem;
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
}
.bannerContainer {
  background-image: url("../../assets/bannerImg.webp");
  background-size: cover;
  background-position: center;
  border-radius: 0.9375rem;
  margin: auto 1.875rem;
  box-shadow: 0rem 0rem 0.6875rem rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 0.9375rem;
  -moz-border-radius: 0.9375rem;
  -ms-border-radius: 0.9375rem;
  -o-border-radius: 0.9375rem;
  padding-left: 4.375rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
  padding-top: 2.5rem;
}

.addEventModal .content {
  margin: 1.875rem 6rem 0.25rem 6rem;
}

.addEventModal .content.margin {
  margin: 1.875rem 3.5rem 0.25rem 3.5rem;
}

.addEventModal .grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.25rem;
  margin-top: 0.625rem;
}
.addEventModal .packageGrid {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-gap: 1.25rem;
  margin-bottom: 0.625rem;
}
.inputContainer textarea {
  margin-bottom: 1.25rem;
  font-size: 0.9375rem;
  font-weight: 300;
  border-radius: 0.4375rem;
  font-family: inherit;
  background: #f6f7ff;
  resize: none;
  border: none;
  min-height: 15rem;
  outline: none;
  width: 100%;
  padding: 0.9375rem;
  box-shadow: 0rem 0rem 0.625rem 0rem rgba(0, 0, 0, 0.1) inset;
}
.inputContainer textarea::placeholder {
  color: #abafd1;
}
.terms {
  font-weight: 400;
}
.previewText {
  font-weight: 600;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
  text-align: center;
  margin-bottom: 1.25rem;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.9375rem;
}

.addEventModal {
  max-height: 95vh;
  overflow: auto;
}

.addEventModal .gridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr) 0.25fr;
  align-items: center;
  grid-gap: 0.625rem;
}

.addEventModal .gridContainer .header {
  color: #323a52;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.addEventModal .gridContainer.seatSelection {
  grid-template-columns: 4.125rem 1.5fr repeat(3, 1fr) 0.5fr 0.25fr;
  padding-left: 0;
}

.addEventModal .gridContainer.seatSelection .priceInput {
  background-color: transparent;
  width: 4ch;
}

.addEventModal .gridContainer.seatSelection .priceInputContainer {
  border-radius: 0.3125rem;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem 0rem rgba(0, 0, 0, 0.1) inset;
  width: max-content;
  padding: 9px 14px;
}

.addEventModal .gridContainer.seatSelection .colorBlock {
  background-color: #ff7a03;
  height: 100%;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  padding-right: 1rem;
  width: 3.125rem;
}

.addEventModal .gridContainer.seatSelection .colorBlockContainer {
  width: 100%;
  height: 100%;
}

.seatSelectionContainer {
  max-height: 290px;
  overflow: auto;
  padding-right: 10px;
  margin-right: -10px;
  padding-left: 10px;
  margin-left: -10px;
  padding-top: 10px;
}

.addEventModal .headers {
  padding: 0 1.375rem;
}

.addEventModal .values {
  min-height: 3.1875rem; /* Minimum height for values */
  border-radius: 0.4375rem;
  background: #f6f7ff;
  padding: 0 1.375rem;
  box-shadow: 0rem 0rem 0.625rem 0rem rgba(0, 0, 0, 0.1);
  margin-bottom: 0.3125rem;
}

.addEventModal .value {
  color: #323a52;
  font-size: 0.9375rem;
  font-style: normal;
  display: flex;
  font-weight: 300;
}

.addEventModal .closeButton {
  margin-left: auto;
  cursor: pointer;
}

.addEventModal .addSale {
  grid-template-columns: repeat(5, 1fr) 0.25fr;
}

.headers.addSale {
  margin-top: 0.75rem;
}

.addEventModal .content .bottomBtns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.1875rem;
}

.customInput {
  background: #f6f7ff;
  box-shadow: inset 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  height: 15.1875rem;
}

.packageDetails {
  background: #f6f7ff;
  box-shadow: inset 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.packageInput {
  background: #f6f7ff;
  box-shadow: inset 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.packageInput::placeholder {
  color: #abafd1;
}
.selectedPackage {
  background-color: #f6f7ff;
  border-radius: 0.4375rem;
  box-shadow: 0rem 0rem 0.625rem #0000001a;
  height: 19.375rem;
  width: 23.8125rem;
  overflow-y: auto;
  padding: 1.25rem 1.25rem 1.25rem 1.625rem;
}
.valueBox {
  display: grid;
  grid-template-columns: 0.75fr 1fr;
  margin-bottom: 1.25rem;
}

.eventDetailsCard {
  background-color: #f6f7ff;
  border-radius: 0.4375rem;
  box-shadow: 0rem 0rem 0.625rem #0000001a;
  height: 21rem;
  width: 24.0625rem;
  overflow: hidden;
  margin: auto;
}
.planType {
  height: 6.3125rem;
  width: 24.0625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 1.25rem 0rem;
  /* border-bottom: .0625rem solid #000; */
  gap: 0.9375rem;
}

.planType img {
  height: 3.8125rem;
  width: 3.8125rem;
}

.headText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.heading {
  color: #757af9;
  font-family: "Poppins";
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 2.0625rem;
  text-transform: uppercase;
}
.subheading {
  font-family: "Poppins";
  font-size: 0.9375rem;
  line-height: 1.4063rem;
  font-weight: 25rem;
}
.details {
  margin: 1.25rem;
}
.contentBox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.eventBorder {
  width: 100%;
  height: 1px;
  max-width: calc(100% - 2.5rem);
  margin: 0 auto;
  background: #d5d5d5;
}
.detailLabel {
  font-family: "Poppins";
  font-size: 0.9375rem;
  line-height: 1.4063rem;
  font-weight: 31.25rem;
}

.custom-arrow {
  position: absolute;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

.prev-arrow {
  left: -1.25rem;
}

.next-arrow {
  right: -1.25rem;
}

.next-arrow img {
  transform: rotate(180deg);
}

.slider-container {
  position: relative;
}

.secondaryHeader {
  padding-right: 1.25rem;
  padding-top: 1.25rem;
  display: flex;
  justify-content: flex-end;
}

.slider .label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slider .label img {
  cursor: pointer;
}

.acknowledgement {
  font-size: 2.3125rem;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  width: 34.5625rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.acknowledgementBox {
  background-color: #f6f7ff;
  border-radius: 0.9375rem;
  box-shadow: 0rem 0rem 0.625rem #00000026;
  height: 18.8125rem;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 43.75rem;
  outline: none;
}
.previewBox {
  background-color: #f6f7ff;
  border-radius: 0.9375rem;
  box-shadow: 0rem 0rem 0.625rem #00000026;
  height: 33.0625rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 44.9375rem;
  outline: none;
}

.previewHeader {
  color: #323a52;
  display: flex;
  justify-content: space-between;
  font-size: 1.5625rem;
  font-weight: 600;
  margin-top: 1.25rem;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}
.previewHeader img {
  cursor: pointer;
}
.previewImg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.previewImg img {
  height: 500px;
  pointer-events: none;
}

.moreInfoBox {
  background-color: #f6f7ff;
  border: none;
  border-radius: 0.9375rem;
  box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.15);
  color: #323a52;
  left: 50%;
  max-width: 72.0625rem;
  outline: none;
  padding: 1.25rem 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 52.9375rem;
}

.uploadWrapper {
  border-radius: 0.4375rem;
  background: #f6f7ff;
  height: 3.1875rem;
  display: flex;
  align-items: center;
  box-shadow: 0rem 0rem 0.625rem 0rem rgba(0, 0, 0, 0.1);
}

.uploadText {
  color: #767bfa;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding-left: 1rem;
  cursor: pointer;
  text-decoration-line: underline;
}

.whatsIncludedWrapper {
  position: relative;
}

.whatsIncludedWrapper textarea {
  height: 12.0625rem;
  resize: none;
  width: 100%;
  border-radius: 0.4375rem;
  background: #f6f7ff;
  border: none;
  outline: none;
  font-family: inherit;
  padding: 0.875rem 1.375rem;
  box-shadow: 0rem 0rem 0.625rem 0rem rgba(0, 0, 0, 0.1) inset;
}

.placeholder {
  position: absolute;
  top: 0.875rem;
  left: 1.375rem;
  color: #abafd1;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  pointer-events: none;
}

.whatsIncluded {
  height: 12.0625rem;
  resize: none;
  width: 100%;
  border-radius: 0.4375rem;
  background: #f6f7ff;
  border: none;
  outline: none;
  font-family: inherit;
  padding: 0.875rem 1.375rem;
  box-shadow: 0rem 0rem 0.625rem 0rem rgba(0, 0, 0, 0.1) inset;
}

.packageFlex {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  grid-column: span 2;
}

.seatMap {
  margin-bottom: 1rem;
}

.seatMap .uploadWrapper {
  max-width: 300px;
}

.eventDetailsCardContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  gap: 1.5rem 0;
}

.lastStep {
  display: flex;
  justify-content: space-evenly;
}

.captcha {
  height: max-content;
  max-width: 49.625rem;
  width: 100%;
}

.captchaRow {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 2fr;
  grid-gap: 1.25rem;
  padding: 0 3.75rem;
  margin-top: 1.5625rem;
}

.captchaRow .addBtnContainer {
  display: flex;
  height: 100%;
  align-items: flex-end;
}

.captchaRow .addBtnContainer div {
  min-width: 6.4375rem;
}

.pad {
  margin-top: 1.25rem;
  margin-bottom: 1.875rem;
  padding: 0 3.75rem;
}

.captchaAnswer {
  border-radius: 0.4375rem;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem 0rem rgba(0, 0, 0, 0.1);
  min-height: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #323a52;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding-left: 1.5rem;
  padding-right: 1rem;
}

.captchaAnswer img {
  cursor: pointer;
}

.captcha .twoGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.875rem;
}

.captcha .btnContainer {
  margin-bottom: 1.5625rem;
}

.answersContainer {
  max-height: 8.25rem;
  overflow-y: auto;
  padding: 8px;
  margin: -8px;
  padding-bottom: 0.5rem;
}

.form {
  display: grid;
  gap: 1.5rem 1rem;
  grid-template-columns: repeat(4, 14.0625rem);
  margin-bottom: 0.7813rem;
  margin-top: 1.25rem;
}

.themeContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 59.25rem;
  width: 100%;
  margin: auto;
}

.progressDisplayOptions,
.themeOptions {
  margin: 0.75rem 0;
}

.checkboxGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: row;
}

.captcha .btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.25rem;
  margin-bottom: 1.25rem;
}

.vipRow {
  display: grid;
  grid-template-columns: 1.25fr 1fr;
}

.modal {
  padding: 20px;
  border-radius: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}

.modalTitle {
  color: #fa7676;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
}

.modalContent {
  margin-bottom: 24px;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  color: #323a52;
}

.modalContent span {
  text-transform: lowercase;
}

.modalActions {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.button {
  border: none;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border-radius: 7px;
  background: #f6f7ff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  font-family: inherit;
  min-height: 50px;
  font-size: 15px;
  font-weight: 600;
}

.confirm {
  color: #12c519;
  background-color: white;
}

.cancel {
  color: #fa7676;
  background-color: white;
}

.deleteModalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 510px;
  border: none;
  outline: none;
  background-color: #f6f7ff;
  border-radius: 15px;
}

.deleteModalContainer .modal {
  min-height: 300px;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #323a52;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
}

.cursor {
  cursor: pointer;
}

.modalForm label {
  margin-bottom: 10px;
  display: block;
  margin-top: 20px;
  color: #323a52;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  padding-left: 20px;
}

.areaEntry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 42px;
  padding: 0 20px;
  border-bottom: 1px solid #abafd1;
}

.timeInputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 24px;
}

.timeInput {
  width: 100%;
}

.addConfiguration {
  display: flex;
  justify-content: flex-end;
}

.areaContainer {
  max-height: 200px;
  overflow: auto;
}

.addNewCardDesignModal {
  max-width: 510px;
  background: #f6f7ff;
}

.addNewCardDesignModal .cardDesignGrid {
  display: grid;
  grid-template-columns: 1fr 382px;
  gap: 1.25rem;
  margin-top: 1.25rem;
}

.addNewCardDesignModal .twoGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;
}

.addNewCardDesignModal .fullWidth {
  width: 100%;
}

.addNewCardDesignModal .preview {
  border-radius: 7px;
  background: #f6f7ff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
}

.addNewCardDesignModal .previewContainer {
  height: 100%;
  display: grid;
  align-items: flex-start;
  grid-template-rows: auto 1fr;
}

.addNewCardDesignModal .leftSection {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.addNewCardDesignModal .addButtonContainer {
  margin-top: 2.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & button {
    color: #767bfa;
  }
}

.shortName {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #d8e6ff;
  color: #4d83f4;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
}

.fullName {
  margin-left: 1rem;
  font-weight: 500;
  display: inline-flex;
}

.fullNameContainer {
  display: flex;
  align-items: center;
}

.loader {
  width: max-content;
  margin: auto;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  min-width: 9.0625rem;
}

.webpageModal {
  background-color: #f6f7ff;
  border-radius: 0.9375rem;
  box-shadow: 0rem 0rem 0.625rem #00000026;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 36.9375rem;
  outline: none;
}

.content {
  padding: 1.25rem;
}

.add {
  border-radius: 0.4375rem;
  background: #f6f7ff;
  width: 14.0625rem;
  height: 10.8125rem;
  box-shadow: 0rem 0rem 0.625rem 0rem rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-bottom: 1.25rem;
  align-items: center;
}

.editContainer {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
}

.editInput {
  width: 100%;
  min-height: 5.875rem;
  border-radius: 0.4375rem;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.625rem 0rem rgba(0, 0, 0, 0.1) inset;
  border: none;
  outline: none;
  font-family: "Poppins", sans-serif;
  padding: 0.8125rem 1.375rem;
  font-size: 0.9375rem;
  font-weight: 300;
  resize: none;

  &::placeholder {
    color: #abafd1;
  }
}
.pickerContainer {
  max-width: 14.0625rem;
}

.buttonContainer {
  position: absolute;
  z-index: 100;
  height: max-content;
  width: max-content;

  button {
    color: #767bfa;
    font-family: "Poppins", sans-serif !important;
  }
}
