.box {
  background-color: #f6f7ff;
  box-shadow: 0 0 0.6875rem rgba(0, 0, 0, 0.149);
  padding: 0.625rem;
  border-radius: 0.9375rem;
}

.themeSection {
  display: flex;
  min-width: 0;
  margin-left: -10px;
  overflow-x: auto;
  padding: 10px;
  gap: 1.5625rem;

  &::-webkit-scrollbar {
    display: none;
    width: 0px;
  }
}

.currentTheme {
  border-radius: 0.4375rem;
  min-height: 10.875rem;
  min-width: 28.125rem;
  background-color: rgba(255, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 600;
}

.themeComponent {
  position: relative;
  border-radius: 0.4375rem;
  min-height: 10.875rem;
  min-width: 28.125rem;
  background-color: rgba(255, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 600;
  color: #f6f7ff;
  cursor: pointer;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0.4375rem;
    background-color: rgba(0, 0, 0, 0.35);
    z-index: 1;
  }

  span {
    text-transform: uppercase;
    position: relative;
    z-index: 2;
  }
}

.themeSection .box {
  transition: all 0.15s ease-in-out;

  &:hover {
    transform: scale(1.0125);
  }
}

.previewHeader {
  position: relative;
  display: flex;
  align-items: center;
}

.websiteLink {
  min-width: 22.5rem;
  display: flex;
  width: max-content;
  align-items: center;
  color: #abafd1;
  padding: 0.1875rem 0.875rem;
  margin-right: 1.25rem;
  border-radius: 0.3125rem;
  box-shadow: inset 0 0 0.6875rem rgba(0, 0, 0, 0.15);

  & input {
    color: #abafd1;
  }
}

.saveBtn {
  background-color: #f6f7ff;
  box-shadow: 0 0 0.6875rem rgba(0, 0, 0, 0.149);
  border-radius: 0.3125rem;
  color: #767bfa;
  height: 1.75rem;
  width: 5.625rem;
  font-family: inherit;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
}

.previewText {
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-weight: 600;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.previewSection {
  padding-top: 1.5625rem;
}

.websitePreview {
  overflow-y: auto;
  margin-top: 0.75rem;
  display: flex;
  font-weight: 600;
  margin-bottom: 1rem;
  height: 61vh;
}

.copyWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 0.675rem;
}
