.inputField {
  background: #f6f7ff;
  box-shadow: inset 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4375rem;
  padding: 0.875rem 1.375rem;
  width: 100%;
  border: none;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}

.dark .inputField {
  background: #3b435e;
  color: #f6f7ff;
}

.shadowOffset {
  box-shadow: 0rem 0.125rem 0.625rem rgba(0, 0, 0, 0.1);
  cursor: not-allowed;
}

.inputField::placeholder {
  font-family: inherit;
  font-weight: 300 !important;
  line-height: 1.375rem;
  font-size: 0.9375rem;
  color: #abafd1;
  opacity: 1 !important;
}

.dark .inputField::placeholder {
  color: #5a5d74;
}

.suffix {
  color: #767bfa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sm {
  max-width: 2.8125rem;
  padding: 0;
  width: 100%;
  height: 2.125rem;
  text-align: center;
}

.smtext {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.375rem;

  padding: 0.5625rem 0.75rem !important;
}

.small {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 9.0625rem;
  width: 100%;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.5625rem rgb(0 0 0 / 15%);
  border-radius: 0.3125rem;
  height: 1.75rem;
  padding: 0 0.625rem 0 0.75rem;
}

.dark .small {
  background: #353c53;
}

.large {
  height: 4.0625rem;
  font-weight: 400;
  font-size: 1.2625rem;
  line-height: 2.0125rem;
}

.large::placeholder {
  font-weight: 400;
  font-size: 1.5625rem;
  line-height: 2.3125rem;
}

.prefixInput {
  padding: 0 !important;
  width: 100%;
  box-shadow: none;
  height: 100%;
}

.prefix {
  font-size: 0.75rem;
  white-space: nowrap;
  margin-right: 0.125rem;
  max-width: 20ch;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dark .sm,
.dark .lightinput {
  background-color: #464f6d !important;
}

.askInputWrap {
  border-radius: 0.4375rem;
  /* padding: 0.875rem 1.375rem; */
  border: 1px solid rgba(50, 58, 82, 0.35);
  width: 100%;
  color: #000;
  font-size: 0.9375rem;
  line-height: 1.375rem;
}

.askInputCurrency {
  font-size: 0.9375rem;
  margin-right: 0.125rem;
  font-weight: 500;
  border-right: 1px solid rgba(50, 58, 82, 0.35);
  padding: 0.625rem 1rem;
}

.dark .askInputWrap,
.dark .askInputCurrency {
  border-color: #f6f7ff59;
  color: #f6f7ff;
}

.askInput {
  padding-left: 0.625rem;
  font-family: inherit;
  font-weight: 500 !important;
  line-height: 1.375rem;
  font-size: 0.9375rem;
  background-color: inherit;
  border: none;
  width: 100%;
}

.dark .askInput {
  color: #f6f7ff;
}

.discount {
  max-width: 9.5125rem;
  padding: 0;
  width: 100%;
  height: 1.825rem;
  text-align: center;
}
