.container {
  grid-column: span 2;
  height: 11rem;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  background-image: url("../../assets/images/eventDetailsBg.png");
  background-size: cover;
  background-repeat: no-repeat;

  border-radius: 15px;
}

.eventName {
  font-size: 1.5625rem;
  font-weight: 300;
  color: #fff;
}

.eventDate {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 0.875rem;
  border-right: 1px solid #abafd1;
}

.month {
  color: #fff;
  font-size: 0.9375rem;
  font-weight: 600;
}

.venueHeader {
  color: #abafd1;
  font-weight: 300;
  font-size: 0.9375rem;
}

.venueText {
  color: #fff;
  font-weight: 500;
  font-size: 0.9375rem;
}

.eventImage {
  width: 9rem;
  height: 6rem;
  border-radius: 7px;
  object-fit: cover;
}

.arrowImage {
  cursor: pointer;
}

.noEvents {
  color: #fff;
  font-size: 1.5625rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
