.container {
  border-radius: 0.9375rem;
  background: #f6f7ff;
  padding: 30px 0;
  box-shadow: 0rem 0rem 0.6875rem 0rem rgba(0, 0, 0, 0.15);
}

.header {
  color: #323a52;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 1.875rem;
}

.recentActivityItem {
  display: flex;
  gap: 1.875rem;
  margin-top: 1rem;
  padding: 0 1.875rem;
  margin-bottom: 0.25rem;
}

.userIcon {
  min-width: 3.75rem;
  height: 3.75rem;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeAgo {
  color: #323a52;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 0.625rem;
}

.name {
  color: #323a52;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.text {
  color: #95979f;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.activity {
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.activity.scan {
  color: #fc40d3;
}

.activity.pos {
  color: #fc40d3;
}

.activity.purchase {
  color: #31a5da;
}

.wrapper {
  height: 28rem;
  overflow-y: auto;
}
