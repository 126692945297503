.header {
  color: #323a52;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.9375rem;
}

.box {
  padding: 1.25rem 1.875rem 1.875rem;
  border-radius: 0.9375rem;
  background: #f6f7ff;
  height: 465px;
  overflow: auto;
  box-shadow: 0rem 0rem 0.6875rem 0rem rgba(0, 0, 0, 0.15);
}

.twoGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.875rem;
  margin-top: 1.25rem;
}

.smHeader {
  color: #323a52;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.seatsSvg {
  display: grid;
  grid-template-columns: repeat(5, 1fr) 30px;
}

.query {
  color: #323a52;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.value {
  color: #323a52;
  font-size: 0.9375rem;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.value span {
  color: #a9aab1;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
