.container {
  margin-top: 1.25rem;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 9.0625rem;
}

.headers,
.values {
  display: grid;
  grid-template-columns: 1.2fr 1.5fr 1fr 1.2fr 1.5fr 1fr;
  align-items: center;
  padding: 0 1.25rem;
  grid-column: 1/3;
}

.headers {
  color: #323a52;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.5rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 55.875rem;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  min-height: 9.25rem;
}

.gridItem {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.625rem;
  gap: 0.9375rem;
}

.gridItem img {
  width: 3.8125rem;
  height: 3.8125rem;
  object-fit: contain;
}

.gridItem:first-child,
.gridItem:nth-child(2) {
  border-right: 0.0625rem solid #d5d5d5;
}

.bold {
  color: #323a52;
  text-align: center;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0.3125rem;
  line-height: normal;
}

.subText {
  color: #323a52;
  text-align: center;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.header {
  color: #323a52;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 0.9375rem;
}

.box {
  border-radius: 0.9375rem;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem 0rem rgba(0, 0, 0, 0.15);
  padding-bottom: 2rem;
  min-height: 15rem;
}

.gridData {
  padding: 0 1.25rem;
}

.values {
  border-radius: 0.3125rem;
  border: 0.0625rem solid #ebebeb;
  background: #f6f7ff;
  box-shadow: 0rem 0rem 0.6875rem 0rem rgba(0, 0, 0, 0.15);
  min-height: 2.6875rem;
  color: #323a52;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0.3475rem;
}
