@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400..900&display=swap");

.page {
  grid-column: 1/3;
  font-family: "Maven Pro", sans-serif;
  background-image: linear-gradient(202deg, #be65c5, #ffdfb8);
  width: 100%;
}

.banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: linear-gradient(to right, pink, purple);
  color: #fff;
  padding-left: 3.5625rem;
  padding-right: 5.625rem;
  height: 10.3125rem;
}

.logo {
  width: 9.375rem;
  height: auto;
  cursor: pointer;
}

.textContainer {
  margin-left: 25vw;
  display: flex;
  align-items: center;
  gap: 3.75rem;

  a {
    color: #fff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  position: relative;
}

.hero {
  position: relative;
  padding-right: 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.heroText {
  transform: translateX(7rem);
  position: relative;
  z-index: 3;
  min-width: 49rem;
}

.primaryText {
  color: #fff;
  font-size: 5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: max-content;
  max-width: 15ch;
  position: relative;
}

.secondaryText {
  color: #fff;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: max-content;
  max-width: 20ch;
  position: relative;
}

.heroImageContainer {
  max-width: 50.8125rem;
  height: 31.75rem;
  width: 100%;
  background-color: black;
  border-radius: 2.1875rem;
  border: 0.1875rem solid #fff;
  overflow: hidden;
  position: relative;
}

.heroImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section {
  height: 15.9375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-size: 5rem;
  font-weight: 700;
  border-top: 3px dashed #ffe973;
  border-bottom: 3px dashed #ffe973;
  margin: 0 3.5625rem;
  position: relative;
}

.events {
  position: relative;
  padding-top: 6.9375rem;
  padding-bottom: 6.9375rem;
}

.eventImage {
  border-radius: 0.9375rem;
  border: 0.125rem solid #fff;
  object-fit: cover;
  max-width: 27.5rem;
  height: 18.1875rem;
  width: 100%;
}

.eventDescription {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 40.125rem;
  width: 100%;
}

.eventName {
  font-family: "Maven Pro";
  font-size: 2.1875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 1.3125rem;
}

.eventDetails {
  font-family: "Maven Pro";
  font-size: 1.5625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.eventPrice {
  font-family: "Maven Pro";
  font-size: 2.1875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 1.875rem;
}

.viewDetailsBtn {
  border-radius: 3.0625rem;
  border: 2px solid #fff;
  width: max-content;
  max-width: 21rem;
  width: 100%;
  height: 3.4375rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 3.125rem;
  padding-right: 2.0625rem;
  text-align: center;
  font-family: "Maven Pro";
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: auto;
  cursor: pointer;
}

.event {
  display: flex;
  justify-content: center;
  gap: 2.5rem;

  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  &:not(:last-child) {
    margin-bottom: 12.5rem;
  }
}

.products {
  padding-top: 6.6875rem;
  padding-bottom: 5.6875rem;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  justify-content: center;
  align-items: center;
}

.product {
  display: flex;
  gap: 2.5rem;
  width: 100%;
  justify-content: center;

  .viewDetailsBtn {
    border: 2px solid #000;
    color: #000;

    svg path {
      stroke: #000;
    }
  }
}

.productImage {
  width: 18.75rem;
  height: 18.75rem;
  border-radius: 0.9375rem;
  border: 0.125rem solid #000;
  object-fit: contain;
}

.productDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 40.125rem;
  width: 100%;
}

.productName {
  color: #000;
  font-family: "Maven Pro";
  font-size: 2.1875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.productDescription {
  color: #000;
  font-family: "Maven Pro";
  font-size: 1.5625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 1.5625rem;
  margin-bottom: 1.5625rem;
}

.productPrice {
  color: #000;
  font-family: "Maven Pro";
  font-size: 2.1875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.footer {
  position: relative;
  background-color: #e2faff;
  padding: 0 7.1875rem;
  padding-top: 3.9375rem;
  padding-bottom: 1.5625rem;
  min-height: 36rem;
  display: flex;
  flex-direction: column;
}

.footerContainer {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  /* border-top: 3px dashed #31b8de; */
  /* border-bottom: 3px dashed #31b8de; */
  padding-top: 2.8125rem;
  padding-bottom: 2.8125rem;
  padding-left: 1.5rem;
  min-height: 27.875rem;
}

.footerSection {
  flex: 1;
}

.footerHeading {
  color: #31b8de;
  font-family: "Maven Pro";
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 2.8125rem;
}

.footerList {
  list-style: none;
  padding: 0;
  li {
    display: block;
    margin-bottom: 1.5625rem;
    color: #31b3ce;
    font-family: "Maven Pro";
    font-size: 1.125rem;
  }
}

.socialLink {
  display: block;
  margin-bottom: 1.5625rem;
  color: #31b3ce;
  font-family: "Maven Pro";
  font-size: 1.125rem;
}

.copyright {
  text-align: center;
  color: #888;
  margin-top: auto;
  color: #31b8de;
  padding-top: 1.5rem;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 2.25rem;
  justify-content: center;
}

.cartContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.footerLogo {
  height: 10.3125rem;
  object-fit: cover;
}

.footerLogoContainer {
  flex: 2;
}

.footerLogoWrapper {
  width: 63%;
  height: 100%;
  /* border-right: 3px dashed #31b8de; */
}
